<!--
 * @Description:
 * @Autor: Zhongyu
 * @Date: 2021-09-03 14:21:23
 * @LastEditors: Lizy
 * @LastEditTime: 2021-12-07 17:13:47
-->
<template>
  <div class="purchase-completed">
    <div class="personal-center-title">
      已完成
    </div>
    <div class="install-body">
      <div class="install-body-header">
        <div class="header-left">
          <span class="title">已完成订单</span>
          <span class="digital">{{ total }}条</span>
        </div>
        <div class="header-right">
          <el-input
            placeholder="搜索订单"
            v-model="search"
            class="input-with-select"
          >
            <el-button
              slot="append"
              icon="iconfont icon-sousu"
              @click="handleSearch"
            ></el-button>
          </el-input>
          <el-button
            icon="iconfont icon-shaixuan1"
            @click="isOpenSearch = !isOpenSearch"
          ></el-button>
        </div>
      </div>
      <div class="install-body-search" :class="{ open: isOpenSearch }">
        <!-- 320 -->
        <div class="search-item">
          <div class="label">订单号：</div>
          <el-input
            v-model="form.orderNum"
            placeholder="请输入订单号"
          ></el-input>
        </div>
        <div class="search-item">
          <div class="label">订单名称：</div>
          <el-input
            v-model="form.productName"
            placeholder="请输入订单名称"
          ></el-input>
        </div>
        <div class="search-item">
          <div class="label">产品类型：</div>
          <el-select
            :popper-append-to-body="false"
            v-model="form.productTypeId"
            placeholder="请选择"
          >
            <el-option label="请选择" value=""></el-option>
            <el-option
              v-for="item in goodTypeList"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            ></el-option>
          </el-select>
        </div>
        <div class="search-item">
          <div class="label">实付金额：</div>
          <div class="price-input">
            <el-input-number
              v-model="form.starPrice"
              :value="form.starPrice"
              :precision="2"
              placeholder="最低价"
              @blur="blurPrice"
            ></el-input-number>
            -
            <el-input-number
              v-model="form.endPrice"
              :precision="2"
              placeholder="最高价"
              @blur="blurPrice"
            ></el-input-number>
          </div>
        </div>
        <div class="search-item">
          <div class="label">完成开始时间：</div>
          <el-date-picker
            :append-to-body="false"
            v-model="form.timeS"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="search-item">
          <div class="label">完成结束时间：</div>
          <el-date-picker
            :append-to-body="false"
            v-model="form.timeD"
            type="date"
            placeholder="选择日期"
          >
          </el-date-picker>
        </div>
        <div class="search-item search-item-btn">
          <div class="search-btn" @click="handleSearch">搜索</div>
        </div>
      </div>
      <div class="install-body-list">
        <table>
          <thead>
            <tr class="list-header">
              <th class="header-item item-278">
                产品信息
              </th>
              <th class="header-item item-346">
                我的信息
              </th>
              <th class="header-item item-206">
                实付金额
              </th>
              <th class="header-item item-130">
                订单详情
              </th>
            </tr>
          </thead>
        </table>
        <table v-show="tableData.length > 0">
          <tbody class="list-line-item" v-for="(item, i) in tableData" :key="i">
            <tr class="sep-row">
              <td colspan="4"></td>
            </tr>
            <tr>
              <td colspan="4" class="line-item-colspan">
                <span class="order-number"> 订单号：{{ item.orderNum }} </span>
                <span class="time"> 完成时间：{{ item.confirmTime }} </span>
              </td>
            </tr>
            <tr>
              <td class="line-item item-278">
                <div class="img-or-name">
                  <img :src="$utils.picturePrefix(item.product.image)" />
                  <span style="width: 130px;">
                    {{ item.product.name }}
                  </span>
                </div>
              </td>
              <td class="line-item item-346">
                <div class="real-name">
                  {{ item.consigneeName }}
                </div>
                <div class="address">
                  {{ item.consigneeAddress.replace(/,/g,"") }}
                </div>
                <div class="phone">
                  {{ item.consigneePhone }}
                </div>
              </td>
              <td class="line-item item-206">
                <span class="real-pay"
                  >￥{{ $utils.formatMoney(item.money) }}</span
                >
              </td>
              <td class="line-item item-130">
                <el-link type="primary" @click="handleDetail(item.id)"
                  >查看详情</el-link
                >
              </td>
            </tr>
          </tbody>
        </table>
        <table v-show="tableData.length <= 0">
          <tbody class="list-line-item">
            <tr class="empty-row">
              <td colspan="4">
                暂无数据
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <u-pagination v-if="total > 0"
        :total="total"
        @change="changePagination(item.id)"
      ></u-pagination>
    </div>
  </div>
</template>

<script>
import UPagination from "@/components/UPagination";
import moment from "moment";

export default {
  components: {
    UPagination
  },
  data() {
    return {
      search: "",
      params: {
        num: 10,
        page: 1
      },
      total: 0,
      tableData: [],
      goodTypeList: [], // 产品类型
      isOpenSearch: false,
      form: {
        orderNum: "", // 订单号
        timeS: "", // 服务时间
        timeD: "", // 服务时间
        productName: "", // 产品名称
        productType: "", // 产品类型
        type: undefined // 订单类型
      }
    };
  },
  computed: {
    userId() {
      return this.$store.getters.userId;
    }
  },
  mounted() {
    this.getOrder();
    this.getProductType();
  },
  methods: {
    // 规格 & 产品分类 下拉框
    getProductType() {
      this.$api.getProductType().then(res => {
        let { data } = res;
        this.goodTypeList = data;
      });
    },
    getOrder() {
      let params = {
        search: this.search,
        status: 3,
        id: this.userId,
        ...this.params,
        ...this.form
      };
      params.type = params.type || "";
      params.productType = params.productType || "";
      params.timeS = params.timeS
        ? moment(params.timeS).format("YYYY-MM-DD")
        : ""; // 服务时间
      params.timeD = params.timeD
        ? moment(params.timeD).format("YYYY-MM-DD")
        : ""; // 服务时间
      const loading = this.$loading({
        lock: true,
        text: "查询中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });
      this.$api
        .getOrder(params)
        .then(res => {
          this.tableData = res.data.records;
          this.total = res.data.total;
        })
        .catch(err => {
          this.$message.error(err?.msg);
        })
        .finally(() => {
          loading.close();
        });
    },
    blurPrice() {
      let search_form = { ...this.form };
      if (!isNaN(search_form.starPrice) && !isNaN(search_form.endPrice)) {
        if (search_form.starPrice > search_form.endPrice) {
          let price = search_form.starPrice;
          search_form.starPrice = search_form.endPrice;
          search_form.endPrice = price;
        }
      }
      this.form = search_form;
    },
    changePagination(page, pageSize) {
      this.params = {
        num: pageSize,
        page: page
      };
      this.getOrder();
    },
    handleSearch() {
      this.params.page = 1;
      this.getOrder();
    },
    handleDetail(id) {
      this.$router.push({
        path: "/personal-center/purchase/details",
        query: { type: 3, oid: id }
      });
    }
  }
};
</script>

<style lang="less">
@import "./index.less";
</style>
